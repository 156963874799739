//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NewsCard",
  props: {
    newsCard: {
      type: Object,
      required: true,
    },
  },
};
