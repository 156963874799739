//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bgImage() {
      let image = {};

      if (this.block.backgroundImage.filename.length) {
        image = {
          src: this.block.backgroundImage.filename,
          alt: this.block.backgroundImage.alt,
        };
      } else {
        image = {
          src: "../bg_image.png",
          alt: "A crowd of people cheer at a baseball stadium",
        };
      }

      return image;
    },
  },
};
