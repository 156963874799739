//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InteriorTitle from "~/components/blocks/InteriorTitle.vue";

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        reason: "Info",
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      responseMessage: null,
      responseState: null,
    };
  },
  components: { InteriorTitle },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit() {
      fetch("/", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": "Contact",
          ...this.form,
        }),
      })
        .then((res, err) => {
          if (res.status === 200) {
            this.responseMessage =
              "Thanks for reaching out! We'll be in contact shortly!";
            this.responseState = "success";

            this.form.name = "";
            this.form.email = "";
            this.form.phone = "";
            this.form.message = "";
          } else {
            this.responseMessage =
              "Oops! Looks like something went wrong. Please try again!";
            this.responseState = "error";
          }
        })
        .catch((e) => console.error(e));
    },
  },
};
