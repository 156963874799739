import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=3b503f28&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default,BaseInput: require('/opt/build/repo/components/base/BaseInput.vue').default,BaseTextarea: require('/opt/build/repo/components/base/BaseTextarea.vue').default,BaseRadio: require('/opt/build/repo/components/base/BaseRadio.vue').default,BaseRadioGroup: require('/opt/build/repo/components/base/BaseRadioGroup.vue').default,BaseButton: require('/opt/build/repo/components/base/BaseButton.vue').default})
