//
//
//
//
//
//
//
//
//
//

import IconComponents from '~/utils/registerIconComponents.js'

export default {
  components: {
    ...IconComponents,
  },
  props: {
    file: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
}
