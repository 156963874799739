//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { Portal } from "portal-vue";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Portal,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState("global", ["pageHasModalOpen"]),
  },
  methods: {
    async closeModal() {
      if (this.isOpen) {
        this.isOpen = false;

        await this.$store.commit("global/isModalOpen", false);
        await this.$nextTick();
        await this.$nextTick();
        this.$refs.openButtonRef?.focus();
      }
    },
    async openModal() {
      this.isOpen = true;
      await this.$store.commit("global/isModalOpen", true);
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.closeButtonRef?.focus();
    },
  },
};
