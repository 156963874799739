//
//
//
//
//
//
//
//
//
//
//
//
//

import InteriorTitle from "~/components/blocks/InteriorTitle.vue";

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  components: { InteriorTitle },
};
