//
//
//
//
//
//
//
//
//
//
//
//

import PlayerCard from "~/components/atoms/PlayerCard";

export default {
  name: "RosterGrid",

  components: {
    PlayerCard,
  },

  props: {
    block: {
      type: Object,
      required: true,
    },
  },
};
