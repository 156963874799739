import { render, staticRenderFns } from "./Richtext.vue?vue&type=template&id=9692ac16&"
import script from "./Richtext.vue?vue&type=script&lang=js&"
export * from "./Richtext.vue?vue&type=script&lang=js&"
import style0 from "./Richtext.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default})
