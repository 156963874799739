import { render, staticRenderFns } from "./HomepageHero.vue?vue&type=template&id=4c427af3&scoped=true&"
import script from "./HomepageHero.vue?vue&type=script&lang=js&"
export * from "./HomepageHero.vue?vue&type=script&lang=js&"
import style0 from "./HomepageHero.vue?vue&type=style&index=0&id=4c427af3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c427af3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBackgroundImage: require('/opt/build/repo/components/base/BaseBackgroundImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default,BaseButton: require('/opt/build/repo/components/base/BaseButton.vue').default,BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default})
