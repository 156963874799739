//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewsCard from "~/components/atoms/NewsCard";

export default {
  name: "NewsUpdates",
  components: {
    NewsCard,
  },

  props: {
    block: {
      type: Object,
      required: true,
    },
  },
};
