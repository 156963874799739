//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      default: 4,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    id() {
      return this.label.toLowerCase().replace(" ", "-");
    },
  },
};
