import { render, staticRenderFns } from "./SponsorsCarousel.vue?vue&type=template&id=2f8e51e2&scoped=true&"
import script from "./SponsorsCarousel.vue?vue&type=script&lang=js&"
export * from "./SponsorsCarousel.vue?vue&type=script&lang=js&"
import style0 from "./SponsorsCarousel.vue?vue&type=style&index=0&id=2f8e51e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8e51e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseCarouselSlide: require('/opt/build/repo/components/base/BaseCarouselSlide.vue').default,BaseCarousel: require('/opt/build/repo/components/base/BaseCarousel.vue').default,BaseButton: require('/opt/build/repo/components/base/BaseButton.vue').default})
