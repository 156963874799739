//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
      validator: (value) => {
        const validProps = [
          "text",
          "email",
          "tel",
          "url",
          "search",
          "password",
        ];
        return validProps.includes(value);
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    id() {
      return this.label.toLowerCase().replace(" ", "-");
    },
  },
};
