//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
